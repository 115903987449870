<template>
  <div class="issue">
    <topnav ref="navs" active="smt"></topnav>
    <topmenu active="smt"></topmenu>
    <div class="public_width">
        <div class="issue_wrap">
            <h2 class="issue_title d-flex">
                <span class="line"></span> 
                <span class="txt">SMT常见问题</span>
                <span class="issue_tip">如果有其他疑问，可点击右下角的任一联系方式直接在线咨询客服</span>
            </h2>
            <ul class="issue_list">
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司能承接什么样类型的产品加工？</p>
                      <p class="answer">我司承接从小批量打样到大批量量产所有产品的加工。</p> 
                    </div>
                    
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司SMT是怎么收费的？</p>
                      <p class="answer">我们公司收费方式有两种，打样和小批量（少于1000pcs）我们是按物料种类和数量来计价。批量订单是点数来核算价格的。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司怎样加工打样生产的？</p>
                      <p class="answer">贴片部分我们全部是机器贴片，DIP插件我们是手工焊接。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司SMT加工需要哪些文件？</p>
                      <p class="answer">我司SMT是全机器加工，需要加工产品的钢网文件，PCB文件、BOM物料清单、位号丝印图、元件坐标。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司SMT能代购物料吗？</p>
                      <p class="answer">我司可以承接从PCB打板，BOM物料配单，SMT贴片加工及DIP插件焊接等所有业务。一站式为客户解决样品打样及量产的所有业务。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司SMT对物料和PCB有什么要求吗？</p>
                      <p class="answer">因为是机器贴片，PCB板尽量做成拼版，且加5mm工艺边。贴片物料包装为卷带或者托盘包装，数量加1%的损耗。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司SMT加工后余料是否可以退回。</p>
                      <p class="answer">我司SMT加工后，没单的余料都会跟产品一起返还给客户。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司特殊元器件可以贴片加工吗？</p>
                      <p class="answer">我们的高速贴片机能加工包括BGA、QFN、PLCC等特殊封装的元器件贴片加工，并提供对特殊元器件加工的X-ray检查。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司SMT生产加工后是如何检查的？</p>
                      <p class="answer">贵司有严格的AOI光学检查系统及QC质量检查。检查完全合格的产品才能发货给客户。</p> 
                    </div>
                </li>
                <li class="issue_item">
                    <img class="img" :src="title_icon" />
                    <div>
                      <p class="ask">贵司的插件物料怎么焊接的？</p>
                      <p class="answer">打样阶段的DIP件焊接是全人工焊接的，小批量及以上的为波峰焊接。</p> 
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <foot />
    <toolbar/>
  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";
import { reactive,toRefs } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const store = useStore()
        store.commit('setCount');

        let state = reactive({
            active: "smt",
            title_icon:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/30/%E5%9B%BE%E7%89%87/cjwt-tb.png'
        })
        
        return {
          ...toRefs(state),
        };
    },
    components: {
        topnav,
        topmenu,
        foot,
        toolbar
    }
};
</script>

<style lang="less">
.issue {
    padding: 154px 0 440px;

    .issue_wrap {
        margin: 14px 0 97px;
        padding: 40px 273px 35px 240px;
        background: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
        .issue_title {
            align-items:center;
            margin-bottom: 37px;
            .line {
                width: 4px;
                height: 26px;
                background: #092C8B;
                margin-left: 6px;
            }
            .txt {
                font-size: 24px;
                font-weight: 600;
                line-height: 33px;
                color: #333333;
                margin-left: 26px;
            }
            .issue_tip {
              color: #999;
              font-size: 14px;
              margin-left: 10px;
            }
        }
        .issue_item {
            display:flex;
            align-items: flex-start;
            margin-bottom: 30px;
            .img {
              height: 17px;
              width: 17px;
              margin: 2px 14px 0 0;
            }
            .ask {
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                color: #333333;
                margin-bottom: 6px;
            }
            .answer {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #666666;
            }
        }
    }
}
</style>
